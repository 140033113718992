<template>
    <div>
        <b-button @click="printDocument" size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="secondary">
            <feather-icon icon="PrinterIcon" /> Imprimir
        </b-button>

        <VueHtml2pdf
            id="viewer"
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="10"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="letter"
            pdf-orientation="portrait"
            ref="html2Pdf"
            @progress="handlePdfProgress($event)"
            :html-to-pdf-options="pdfOptions"
        >
        <section slot="pdf-content" class="invoice-preview-wrapper" style="color: black!important; font-size: 7px!important;">
            <section class="pdf-item">
                <div class="pdf-header_ m-2">
                    <b-row class="_match-height invoice-preview" style="font-size: 9px!important;">
                        <b-col cols="3" md="3">
                            <!-- <b-img :src="logo" height="80"/> -->
                        </b-col>

                        <b-col cols="6" md="6">
                            <div style="text-align: center!important;">
                                <h6 v-if="conexion" style="color:black">
                                    <!-- FERREY S.A. de C.V. -->
                                    {{ conexion.nombre }}
                                </h6>
                                <h4 style="color: black;">Cambio de Precios</h4>
                                <h6 class="h6 text-capitalize">Del {{ customFormatDate(dateStart) }} al {{ customFormatDate(dateEnd) }}</h6>
                            </div>                        
                        </b-col>
                        <b-col cols="3" md="3">
                            <h6 style="color:black">                                
                                <small style="color: black;"> Impreso el {{ customFormatDate('', 'DD/MM/YYYY H:mm:ss a') }}</small>
                            </h6>                            
                        </b-col>

                        <b-col cols="12" md="12">
                            <div style="text-align: center!important;">                                
                            </div>
                        </b-col>
                    </b-row>
                </div>

                <!-- body -->
                <div class="m-2">
                    <div v-for="(chunk, index) in chunkedData" :key="index">                            
                        <table class="table b-table position-relative b-table-responsive text-small small table-sm" style="border-collapse: collapse; width: 100%;">
                            <thead>
                                <tr>
                                    <th style="font-size: 8px!important;">COBRADOR</th>              
                                    <th style="font-size: 8px!important;">ARTICULO</th>
                                    <th style="font-size: 8px!important;">PRECIO</th>
                                    <th style="font-size: 8px!important;">ÚLTIMO COSTO</th>
                                    <th style="font-size: 8px!important;">PRECIO ACTUAL</th>
                                    <th style="font-size: 8px!important;">%</th>
                                    <th style="font-size: 8px!important;">PRECIO ANTERIOR</th>
                                    <th style="font-size: 8px!important;">%</th>
                                    <th style="font-size: 8px!important;"></th>
                                </tr>
                            </thead>
                            <tbody v-for="(item, idx) in chunk" :key="index+idx">
                                <tr>
                                    <td class="text-nowrap" colspan="9">{{ item.usuario_Nombre }}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td style="font-size: 8px!important;"><strong>{{ item.clave_Articulo }}</strong></td>
                                    <td style="font-size: 8px!important;" colspan="6"><strong>{{ item.articulo_Nombre }}</strong></td>
                                    <td style="font-size: 8px!important;" class="text-capitalize"><strong>{{ formatDate(item.fecha_Hora, true) }}</strong></td>
                                </tr>

                                <tr v-for="(price, idx) in item.precios" :key="index+idx">
                                    <td style="font-size: 8px!important;" colspan="2"></td>
                                    <td style="font-size: 8px!important;" class="text-nowrap">{{ price.precio_Empresa_Nombre }}</td>
                                    <td style="font-size: 8px!important;">{{ formatCurrency(price.ultimo_Costo) }}</td>
                                    <td style="font-size: 8px!important;">{{ formatCurrency(price.precio_Nuevo) }}</td>
                                    <td style="font-size: 8px!important;">{{ formatoPorcentaje(price.porcentaje_Actual) }}</td>
                                    <td style="font-size: 8px!important;">{{ formatCurrency(price.precio_Anterior) }}</td>
                                    <td style="font-size: 8px!important;">{{ formatoPorcentaje(price.porcentaje_Anterior) }}</td>
                                    <td style="font-size: 8px!important;" class="text-capitalize"></td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-if="index < chunkedData.length - 1" v-page-break-prices></div>
                    </div>
                </div>
            </section>
        </section>
        </VueHtml2pdf>
    </div>
</template>

<script>
import { ref, watch, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, BCardHeader, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BImg,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'

import VueHtml2pdf from 'vue-html2pdf'
import JsBarcode from 'jsbarcode';

export default {
    name: 'PriceChangesPdf',
    directives: {
      Ripple,
      'b-toggle': VBToggle,
    },
    components: {
        BRow,
        BCol,
        BCard,
        BCardBody,
        BCardHeader,
        BTableLite,
        BCardText,
        BButton,
        BAlert,
        BLink, 
        BImg,        
        VueHtml2pdf,
    },
    props:{
        prices: {
            type: Array,
            default: () => []
        },
        dateStart: {
            type: String,
            default: ''
        },
        dateEnd: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            loading: false,
            logo: require('@/assets/images/logo/ferreysa.png'),
            pdfOptions: {
                margin: [5, 0, 0, 2], // top, left, buttom, right
                image: { type: 'jpg', quality: 0.98 },
                filename: `Cambio-Precios.pdf`,
                html2canvas: {
                    scale: 2,
                    bottom: 0,               
                },
                jsPDF: {
                    unit: 'mm',
                    orientation: 'portrait',
                }              
            }
        }
    },
    computed: {
        chunkedData() {
            const firstChunkSize = 9;
            const otherChunkSize = 11;
            let chunks = [];
            let i = 0;

            // Primer grupo de 5 elementos
            if (this.prices.length > 0) {
                chunks.push(this.prices.slice(i, i + firstChunkSize));
                i += firstChunkSize;
            }

            // Grupos restantes de 6 elementos
            while (i < this.prices.length) {
                chunks.push(this.prices.slice(i, i + otherChunkSize));
                i += otherChunkSize;
            }
            return chunks;
        },
        conexion() {
            return store.getters['auth/getConexion']
        },
    },
    mounted () {

    },
    methods: {
        handlePdfProgress(evt) {
          this.$emit('pdf-progress', evt);
        },
        printDocument() {
            this.$refs.html2Pdf.generatePdf()
        },      
    },
}
</script>

<style lang="scss" scoped>
  @import "~@core/scss/base/pages/app-invoice.scss";
  </style>
  
  <style lang="scss">

  th {
    color: black;
  }

  hr {
      margin-top: 0!important;
      margin-bottom: 0px!important;
      border: 0;
      border-top: 1px solid black;
  }

  /* Estilos para la tabla secundaria */
  .custom-inner-table {
    border-collapse: collapse; /* Combina los bordes de las celdas */
    border: 1px solid gray; /* Establece el borde de la tabla secundaria */
    border-radius: 0!important;
  }

  .custom-inner-table th, .custom-inner-table td {
    border: 0.1px solid gray; /* Establece el borde de las celdas de la tabla secundaria */
    padding: 1px; /* Añade espaciado interno */
    border-radius: 0!important;
  }

  /* Estilos específicos para algunos navegadores */
  @supports (-moz-appearance:none) {
    .custom-inner-table th, .custom-inner-table td {
      border: 1px solid gray; /* Restablece el grosor del borde para Firefox */
    }
  }

// .pdf-header {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   border-bottom: 2px solid black;
//   padding-bottom: 10px;
//   margin-bottom: 10px;
// }

.pdf-footer {
  text-align: center;
  font-size: 5px!important;
  border-top: 0px solid #000;
  padding-top: 5px;
  margin-top: 5px;
}
</style>
  
